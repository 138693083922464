'use client'
import i18next from 'i18next'
import { PropsWithChildren, useState } from 'react'
import { createContext } from 'react'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'

export const languageSchema = z.enum(['cs', 'en'])
export type Language = z.infer<typeof languageSchema>

const createInstance = (t: Record<string, string>) =>
    i18next.use(initReactI18next).init({
        lng: 'en', // if you're using a language detector, do not define the lng option
        // TODO: debug only on test
        debug: false,
        resources: {
            en: {
                translation: t,
            },
        },
    })

export const TranslationsContext = createContext<{
    lang: Language
    setLang: (l: Language) => void
    t: any
}>({
    lang: 'cs',
    setLang: () => {},
    t: undefined,
})

export function TranslationsContextProvider({
    children,
    translation,
    defaultLang,
}: PropsWithChildren<{
    translation: Record<string, string>
    defaultLang: Language
}>) {
    const [i18instance] = useState(() => createInstance(translation))
    const [lang, setLang] = useState(defaultLang)

    return (
        <TranslationsContext.Provider
            value={{
                lang,
                setLang,
                t: i18instance,
            }}
        >
            {children}
        </TranslationsContext.Provider>
    )
}
