import(/* webpackMode: "eager", webpackExports: ["TranslationsContextProvider"] */ "/builds/wepro/wepro-fe/context/i18.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/wepro/wepro-fe/lib/context/DebugContext.tsx");
;
import(/* webpackMode: "eager" */ "/builds/wepro/wepro-fe/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/builds/wepro/wepro-fe/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/builds/wepro/wepro-fe/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/builds/wepro/wepro-fe/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/wepro/wepro-fe/trpc/Provider.tsx");
